<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="训练器材"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div style="margin-top: 16px">
            <a class="store" href="https://shop91585674.m.youzan.com/wscgoods/detail/3nfpqnqpsyl0y?scan=1&activity=none&from=kdt&qr=directgoods_838869332&shopAutoEnter=1&showsku=true">
                <vimg :src="require('../../img/TLD3.jpeg')"  style="width: 100%"/>
            </a>

        </div>


        <RLFooter/>
    </div>
</template>

<script>

    import {Image as vimg} from 'vant';

    export default {
        name: 'QueIdx',
        components: {
            vimg
        },
        data(){
            return {
                active:0,
                activeName:'-1'

            };
        },
        computed:{

        },
        methods:{
            toStore(){
                // this.$router.push({path: '/exe/'+send_id});

            }
        }
        ,
        created() {
        },
    }
</script>

<style scoped>

    .is_completed{
        color: #1bde1a;
        /*color: aqua;*/
    }
    .is_uncompleted{
        color:red;
    }

</style>
